import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../styles/about.scss";
import SubHeading from "../Components/sub-heading.tsx";
import Header from "../Components/Header";
import {
  HonestyIcon,
  InnovationIcon,
  OwnershipIcon,
  RightArrowIcon,
  PrevArrowIcon,
  NextArrowIcon,
} from "../Assets/Icons.tsx";
import PopularSearch from "../Components/popular-search.tsx";
import Footer from "../Components/footer.tsx";
const About = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  let sliderRef = useRef(null);
  const openPositions = [
    {
      designation: "Customer Relation",
      level: "Mid level",
      link: "#",
    },
    {
      designation: "Designer",
      level: "Mid level",
      link: "#",
    },
    {
      designation: "Head of Engineering",
      level: "Founding Team",
      link: "#",
    },
    {
      designation: "Sales Navigator",
      level: "Senior level",
      link: "#",
    },
    {
      designation: "Head of Engineering",
      level: "Founding Team",
      link: "#",
    },
    {
      designation: "Software Developer",
      level: "Senior level",
      link: "#",
    },
  ];
  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 1356,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="guesthom-about-us">
      <Header />
      <section className="about-us-hero-section">
        <div className="container">
          <div className="d-flex">
            <div className="left-block">
              <SubHeading heading="About us" />
              <h1>
                Redefining{" "}
                <span style={{ color: "var(--color-mint-green)" }}>
                  Home-Buying & Renting
                </span>{" "}
                Experience
              </h1>
            </div>
            <div className="right-block">
              <p>
                Our goal is to redefine the home-buying and renting experience
                by combining affordability, trust, and advanced technology,
                ensuring everyone finds a place they can proudly call home. We
                are the leading property classifieds website in Ahmedabad
                connecting buyers, investors, landlords, sellers, tenants and
                brokers to facilitate various real estate requirements in a
                seamless, user-friendly manner.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="we-follow">
        <div className="container">
          <div className="d-flex">
            <div className="left-block">
              <div className="d-flex icon-row">
                <div className="value-wrapper">
                  <div className="icon">
                    <HonestyIcon />
                  </div>
                  <div className="value">Honesty</div>
                </div>
                <div className="value-wrapper">
                  <div className="icon">
                    <InnovationIcon />
                  </div>
                  <div className="value">Innovation</div>
                </div>
                <div className="value-wrapper">
                  <div className="icon">
                    <OwnershipIcon />
                  </div>
                  <div className="value">Ownership</div>
                </div>
              </div>
            </div>
            <div className="right-block">
              <SubHeading heading="Something We Strongly Follow" />
              <h2>
                Our Values
                <span style={{ color: "var(--color-mint-green)" }}>
                  Values
                </span>{" "}
                Are As{" "}
                <span style={{ color: "var(--color-mint-green)" }}>
                  Distinctive
                </span>{" "}
                As We Are.
              </h2>
              <p>
                As a homegrown Ahmedabad brand, we are committed to addressing
                the needs of both the local and expat communities. With a deep
                understanding of the regional property market, we’ve developed
                tailored solutions to meet the unique requirements of property
                seekers and agents. Guided by our core values, every decision we
                make is aimed at elevating the market and driving regional
                growth while making property journeys seamless and rewarding for
                everyone involved.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="investors">
        <div className="container">
          <div className="d-flex">
            <div className="left-column">
              <h2>Our Investors</h2>
              <p>
                GuestHom is also the most well-funded tech startup with over
                $4M+ invested by well-known investors like Atlas Investments,
                Antler, Y Combinatorand many more, fortifying our position in
                the global classifieds industry.
              </p>
            </div>
            <div className="right-column">
              <div className="img-block">
                <img src="/images/investors.png" alt="guesthom-investors" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="guesthom-experience">
        <div className="container">
          <SubHeading heading="Our Numbers Speak for Themselves" />
          <h2>
            Discover the <br />
            <span style={{ color: "var(--color-mint-green)" }}>
              {" "}
              GuestHom
            </span>{" "}
            Experience
          </h2>
          <div className="records-wrapper">
            <div className="left-block">
              <div className="money">₹ 298 Cr+</div>
              <div className="track-record">Track Record</div>
            </div>
            <div className="right-block">
              <div className="d-flex numbers-row">
                <div className="number-card">
                  <div className="number">6,000+</div>
                  <p>Commercial and residential Building Guides</p>
                </div>
                <div className="number-card">
                  <div className="number">20+</div>
                  <p>Real Estate Developers Associated with us</p>
                </div>
                <div className="number-card">
                  <div className="number">2,200+</div>
                  <p>Comprehensive Area and Community Guides</p>
                </div>
                <div className="number-card">
                  <div className="number">50,000+</div>
                  <p>Channel Partners</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="leader-section">
        <div className="container">
          <div className="d-flex">
            <div className="left-block">
              <SubHeading heading="Know Our Leader" />
              <h2>
                From <br />
                <span style={{ color: "var(--color-mint-green)" }}>
                  Founder's
                </span>{" "}
                Desk{" "}
              </h2>
              <p>
                His visionary leadership and technological innovations have been
                pivotal in GuestHom’s growth and regional expansion. By focusing
                on cutting-edge solutions and client-centric strategies,
                GuestHom has transformed into a prominent industry leader. Under
                his guidance, the brand has achieved record-breaking milestones,
                including substantial revenue growth and a remarkable increase
                in clientele year after year.
              </p>
            </div>
            <div className="right-block">
              <div className="img-block">
                <div className="founder-image">
                  <img src="/images/founder.png" alt="guesthom-founder" />
                </div>
                <div className="name-section">
                  <h6>Narendra Solanki</h6>
                  <p>CEO, GuestHom</p>
                </div>

                <div className="img-vector">
                  <img
                    src="/images/founder-img-vector.png"
                    alt="guesthom-founder"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="img-bg-vector">
          <img src="/images/bg-vector.png" alt="guesthom-founder" />
        </div>
      </section>
      <section className="careers">
        <div className="container">
          <div className="title-wrapper">
            <SubHeading heading="Careers" bothArrow />
            <h2>
              <span style={{ color: "var(--color-mint-green)" }}>Join</span> Our
              Journey
            </h2>
          </div>
          <div className="slider-wrapper">
            <Slider
              ref={(slider) => {
                sliderRef = slider;
              }}
              {...settings}
            >
              {openPositions.map((home, index) => {
                return (
                  <div className="career-card" key={index}>
                    <div className="career-icon">
                      <img src="/images/career.png" alt="career" />
                    </div>
                    <div className="career-details">
                      <h6>{home.designation}</h6>
                      <p className="position">{home.level}</p>
                      <div className="action-btn">
                        <button className="btn read-more-btn">
                          Apply Now{" "}
                          <span>
                            <RightArrowIcon />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="slider-controls-wrapper">
            <div className="d-flex controls-row">
              <div
                className={`prev-btn ${
                  activeSlide === 0 ? "disabled" : "enabled"
                }`}
                onClick={() => {
                  sliderRef.slickPrev();
                }}
              >
                <PrevArrowIcon color="var(--color-pilot-blue)" />
              </div>
              <div className="slider-range">
                1 <span className="rect-line"></span> {openPositions.length}
              </div>
              <div
                className={`next-btn ${
                  activeSlide === openPositions.length - 1
                    ? "disabled"
                    : "enabled"
                }`}
                onClick={() => {
                  sliderRef.slickNext();
                }}
              >
                <NextArrowIcon color="var(--color-pilot-blue)" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopularSearch />
      <Footer />
    </div>
  );
};
export default About;
