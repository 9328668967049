import React, { useState, useRef } from "react";
import Building from "../Assets/Images/Building1.png";
import Image1 from "../Assets/Images/Image1.jpg";
import Image2 from "../Assets/Images/Image2.jpg";
import SubHeading from "./sub-heading.tsx";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    RightArrowIcon,
    PrevArrowIcon,
    NextArrowIcon,
} from "../Assets/Icons.tsx";

const PropertyCarousel = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    let sliderRef = useRef(null);

    const properties = [
        {
            id: 1,
            name: "Shivalik Alcove III",
            image: Building,
            bhkType: "2,3 BHK & 3B2HK",
            locality: "Sindhu Bhavan Road",
            carpetArea: "2,400 - 3,200 sq. ft.",
            developer: "Shivalik",
        },
        {
            id: 2,
            name: "Pratham Enclave",
            image: Image1,
            bhkType: "2,3 BHK & 3B2HK",
            locality: "Zundal Circle",
            carpetArea: "2,400 - 3,200 sq. ft.",
            developer: "Pratham",
        },
        {
            id: 3,
            name: "Sampad Centrum",
            image: Image2,
            bhkType: "2,3 BHK & 3B2HK",
            locality: "Motera Road",
            carpetArea: "2,400 - 3,200 sq. ft.",
            developer: "Sampad",
        },
    ];

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: false,
        beforeChange: (current, next) => {
            setActiveSlide(next);
        },
    };

    return (
        <div className="min-h-screen property-slider-section">
            <div className="max-w-7xl mx-auto px-4">
                <div className="text-center title-wrapper">
                    <div className="flex items-center justify-center gap-3">
                        <SubHeading
                            color="white"
                            heading="New & Hot Selling"
                            bothArrow
                        />
                    </div>
                    <h2 className="text-white">
                        Hottest{" "}
                        <span className="text-[#84BD00]">New Houses</span> You
                        <br />
                        Definitely Can't Miss!
                    </h2>
                </div>
                <div className="slider-container">
                    <Slider
                        ref={(slider) => {
                            sliderRef = slider;
                        }}
                        {...settings}
                    >
                        {properties.map((property, index) => {
                            return (
                                <div key={index} className="relative">
                                    <div className="aspect-w-16 aspect-h-9 w-full">
                                        <img
                                            src={property.image}
                                            alt={property.name}
                                            className="w-full h-96 object-cover rounded-lg"
                                        />
                                    </div>
                                    <div className="property-description">
                                        <div className="d-flex">
                                            <div className="block">
                                                <h6 className="propert-name">
                                                    {property.name}
                                                </h6>
                                            </div>
                                            <div className="block">
                                                <div className="sub-heading">
                                                    BHK Type
                                                </div>
                                                <p className="desc">
                                                    {property.bhkType}
                                                </p>
                                                <div className="sub-heading">
                                                    Carpet Area
                                                </div>
                                                <p className="desc">
                                                    {property.carpetArea}
                                                </p>
                                            </div>
                                            <div className="block">
                                                <div className="sub-heading">
                                                    Locality
                                                </div>
                                                <p className="desc">
                                                    {property.locality}
                                                </p>
                                                <div className="sub-heading">
                                                    Price
                                                </div>
                                                <button className="btn read-more-btn mint-green underline">
                                                    Know Price
                                                </button>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <button className="btn read-more-btn mint-green">
                                                {`View All Properties by ${property.developer}`}
                                                <span>
                                                    <RightArrowIcon
                                                        color={
                                                            "var(--color-mint-green)"
                                                        }
                                                    />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
            <div className="slider-controls-wrapper">
                <div className="d-flex controls-row">
                    <div
                        className={`prev-btn ${
                            activeSlide === 0 ? "disabled" : "enabled"
                        }`}
                        onClick={() => {
                            sliderRef.slickPrev();
                        }}
                    >
                        <PrevArrowIcon />
                    </div>
                    <div className="slider-range">
                        1 <span className="rect-line"></span>{" "}
                        {properties.length}
                    </div>
                    <div
                        className={`next-btn ${
                            activeSlide === properties.length - 1
                                ? "disabled"
                                : "enabled"
                        }`}
                        onClick={() => {
                            sliderRef.slickNext();
                        }}
                    >
                        <NextArrowIcon />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyCarousel;
