import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

const PriceTrend = () => {
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May"],
    datasets: [
      {
        label: "Price Trend",
        data: [60, 65, 70, 80, 90],
        borderColor: "green",
        borderWidth: 2,
        fill: false,
        pointBackgroundColor: "pink",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    scales: {
      y: { ticks: { callback: (value) => `${value} lacs` } },
    },
  };

  return <Line data={data} options={options} />;
};

export default PriceTrend;
