import React from "react";

const PopularLocations = () => {
  const data = [
    { name: "District 11", price: "1,600", change: "4.9%", increase: true },
    {
      name: "Alpha 1 by Shivalik",
      price: "2,100",
      change: "-2.3%",
      increase: false,
    },
    { name: "The Fields", price: "2,400", change: "10.2%", increase: true },
    { name: "The Fields", price: "2,200", change: "-3.6%", increase: false },
  ];

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Avg. price/sqft</th>
          <th>Change</th>
        </tr>
      </thead>
      <tbody>
        {data.map((location, index) => (
          <tr key={index}>
            <td>{location.name}</td>
            <td>{location.price}</td>
            <td style={{ color: location.increase ? "green" : "red" }}>
              {location.change}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PopularLocations;
