import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const createStripePattern = (color) => {
  const canvas = document.createElement("canvas");
  canvas.width = 10;
  canvas.height = 10;
  const ctx = canvas.getContext("2d");

  // Create diagonal stripes
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, 10, 10);
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(0, 10);
  ctx.lineTo(10, 0);
  ctx.stroke();

  return ctx.createPattern(canvas, "repeat");
};

const AvgPriceSqftChart = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const avgPricePattern = createStripePattern("#032850");
    const askingPricePattern = createStripePattern("#032850");

    setChartData({
      labels: ["Avg. price/sqft", "Asking price"],
      datasets: [
        {
          data: [2000, 1800],
          backgroundColor: [avgPricePattern, askingPricePattern],
          barThickness: 50,
        },
      ],
    });
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw.toLocaleString()} lacs`,
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: { size: 14 },
          color: "#555",
        },
      },
      y: {
        beginAtZero: true,
        grid: { drawBorder: false },
        ticks: {
          stepSize: 500,
          font: { size: 14 },
          callback: (value) => `${value.toLocaleString()} lacs`,
          color: "#555",
        },
      },
    },
  };

  if (!chartData) return null; // Wait until the pattern is ready

  return (
    <div style={{ width: "100%", height: "300px", padding: "10px" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default AvgPriceSqftChart;
