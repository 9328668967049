import React from "react";
import SubHeading from "./sub-heading.tsx";
import "./style.scss";
const PopularSearch = () => {
  return (
    <>
      <section className="popular-searches">
        <div className="container">
          <SubHeading heading="Popular Searches in India" bothArrow />
          <div className="search-result-body">
            <div className="d-flex">
              <div className="result-column">
                <p>Real estate in Ahmedabad</p>
                <p>Real estate in Bengaluru</p>
                <p>Real estate in Mumbai</p>
                <p>Real estate in Hyderabad</p>
                <p>Real estate in Pune</p>
                <p>Real estate in Chennai</p>
                <p>Real estate in Delhi</p>
              </div>
              <div className="result-column">
                <p>Flates in Ahmedabad</p>
                <p>Flates in Bengaluru</p>
                <p>Flates in Mumbai</p>
                <p>Flates in Hyderabad</p>
                <p>Flates in Pune</p>
                <p>Flates in Chennai</p>
                <p>Flates in Delhi</p>
              </div>
              <div className="result-column">
                <p>Flats for Rent in Ahmedabad</p>
                <p>Flats for Rent in Bengaluru</p>
                <p>Flats for Rent in Mumbai</p>
                <p>Flats for Rent in Hyderabad</p>
                <p>Flats for Rent in Pune</p>
                <p>Flats for Rent in Chennai</p>
                <p>Flats for Rent in Delhi</p>
              </div>
              <div className="result-column">
                <p>Real estate in Ahmedabad</p>
                <p>Real estate in Bengaluru</p>
                <p>Real estate in Mumbai</p>
                <p>Real estate in Hyderabad</p>
                <p>Real estate in Pune</p>
                <p>Real estate in Chennai</p>
                <p>Real estate in Delhi</p>
              </div>
              <div className="result-column">
                <p>New Projects in Ahmedabad</p>
                <p>New Projects in Bengaluru</p>
                <p>New Projects in Mumbai</p>
                <p>New Projects in Hyderabad</p>
                <p>New Projects in Pune</p>
                <p>New Projects in Chennai</p>
                <p>New Projects in Delhi</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PopularSearch;
