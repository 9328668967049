import React from "react"
import classNames from 'classnames';

interface SectionTitleProps {
    title: string
    noPadding?: boolean
}
const SectionTitle = (props:SectionTitleProps) =>{
    const {title,noPadding = false} = props
    const className = classNames( 'guesthom-section-title', {
		'no-padding': noPadding,
		} );
    return <>
        <h2 className={className}>
            {title}
        </h2>
    </>
}
export default SectionTitle