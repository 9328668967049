import React from "react";

const SliderSection = () => {
  return (
    <div className="overflow-hidden guesthom-slider-section">
      <div className="mx-auto px-4">
        <div className="relative">
          <div className="flex items-center gap-20 animate-marquee">
            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={"/images/guesthome-slider-img-1.png"}
                alt="Camera company logo"
                className="slider-img"
              />
            </div>

            <div className="flex items-center space-x-4 shrink-0">
              <img
                src={"/images/guesthome-slider-img-1.png"}
                alt="Applay company logo"
                className="slider-img"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={"/images/guesthome-slider-img-1.png"}
                alt="Apply company logo"
                className="slider-img"
              />
            </div>

            {/* Duplicate set for seamless loop */}
            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={"/images/guesthome-slider-img-1.png"}
                alt="Camera company logo"
                className="slider-img"
              />
            </div>

            <div className="flex items-center space-x-4 shrink-0">
              <img
                src={"/images/guesthome-slider-img-1.png"}
                alt="Applay company logo"
                className="slider-img"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={"/images/guesthome-slider-img-1.png"}
                alt="Apply company logo"
                className="slider-img"
              />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes marquee {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .animate-marquee {
          animation: marquee 30s linear infinite;
          display: flex;
        }
      `}</style>
    </div>
  );
};

export default SliderSection;
