import React, { useEffect, useState } from "react";
import Background from "../../src/Assets/Images/MainBGGreen.png";
import Tree from "../../src/Assets/Images/MainBGGreen.png";
import Villa from "../../src/Assets/Images/HouseGreen.png";
import Overlay from "../../src/Assets/Images/LeafsGreen.png";

const Hero = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const handleMouseMove = (e) => {
            const x = (e.clientX / window.innerWidth - 0.5) * 1;
            const y = (e.clientY / window.innerHeight - 0.5) * 1;
            setMousePosition({ x, y });
        };

        window.addEventListener("mousemove", handleMouseMove);
        setTimeout(() => setIsLoaded(true), 100);
        return () => window.removeEventListener("mousemove", handleMouseMove);
    }, []);

    return (
        <div className="relative h-screen w-full overflow-hidden bg-white-100">
            <div className="hero-text-container">
                <div className="hero-text-block">
                    <h1>Roofs and Roots find Both here</h1>
                    <p>
                        Ready to help you with anything from finding a property
                        to finalising the rentals in a snap.
                    </p>
                    <button className="hero-btn">Get Early Access</button>
                </div>
            </div>

            <div
                className={`absolute inset-0 transition-opacity duration-1000 delay-200 ${
                    isLoaded ? "opacity-100" : "opacity-0"
                }`}
                style={{
                    transform: `translate(${mousePosition.x * 30}px, ${
                        mousePosition.y * 30
                    }px)`,
                    transition: "transform 0.3s ease-out",
                }}
            >
                <div
                    className="absolute inset-0"
                    style={{
                        transform: "scale(1.2)",
                        width: "120%",
                        height: "120%",
                        left: "-10%",
                        top: "-10%",
                    }}
                >
                    <img
                        src={Tree}
                        alt="Trees"
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>

            <div
                className={`absolute top-[65%] left-1/2 -translate-x-1/2 transition-opacity duration-1000 delay-400 ${
                    isLoaded ? "opacity-100" : "opacity-0"
                }`}
                style={{
                    transform: `translate(calc(-50% + ${
                        mousePosition.x * 50
                    }px), ${mousePosition.y * 50}px)`,
                    transition: "transform 0.3s ease-out",
                    width: "70%",
                    maxWidth: "1000px",
                }}
            >
                <img src={Villa} alt="Villa" className="w-full h-auto" />
            </div>

            <div
                className={`absolute inset-0 transition-opacity duration-1000`}
            >
                <img
                    src={Overlay}
                    alt="Overlay"
                    className="w-full h-full object-cover"
                />
            </div>
        </div>
    );
};

export default Hero;
