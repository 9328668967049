import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeImg1 from "../Assets/Images/home/home-img-1.png";
import HomeImg2 from "../Assets/Images/home/home-img-2.png";
import {
  BathIcon,
  BedIcon,
  LocationIcon,
  SquareFitIcon,
  VerifiedIcon,
} from "../Assets/Icons.tsx";
import { PrevArrowIcon, NextArrowIcon } from "../Assets/Icons.tsx";
import SubHeading from "./sub-heading.tsx";

const RentalHomesSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  let sliderRef = useRef(null);

  const rentalHomes = [
    {
      price: "80K",
      verified: true,
      amenities: [
        {
          icon: <BedIcon />,
          title: "Beds",
        },
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "2400 sq. ft",
        },
      ],
      location: "Eco La brea- Coliving.",
      img: HomeImg1,
    },
    {
      price: "90K",
      verified: true,
      amenities: [
        {
          icon: <BedIcon />,
          title: "Beds",
        },
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "2500 sq. ft",
        },
      ],
      location: "Bopal Approach",
      img: HomeImg2,
    },
    {
      price: "10K",
      verified: true,
      amenities: [
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "1100 sq. ft",
        },
      ],
      location: "Vadaj",
      img: HomeImg2,
    },
    {
      price: "20K",
      verified: true,
      amenities: [
        {
          icon: <SquareFitIcon />,
          title: "2100 sq. ft",
        },
      ],
      location: "Sindhu bhavan road",
      img: HomeImg1,
    },
  ];

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 1356,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="easy-rental-homes relative">
      <div className="container">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <div className="margin-auto-title">
              <SubHeading heading="Easy Rental Homes ?" bothArrow />
            </div>

            <h2 className="text-5xl font-medium rental-title">
              <span className="text-blue-950 font-nohemi font-normal">
                Hot Spots,
              </span>
              <span
                className="text-green-400 font-nohemi font-normal"
                style={{ paddingLeft: "8px" }}
              >
                Cool rentals. <br />
              </span>
              <span className="text-blue-950 font-nohemi font-normal">
                {" "}
                Find Your Vibe!
              </span>
              <br />
            </h2>
            <div className="container">
              <Slider
                ref={(slider) => {
                  sliderRef = slider;
                }}
                {...settings}
              >
                {rentalHomes.map((home, index) => {
                  return (
                    <div className="col-6 col-md-6 col-sm-12" key={index}>
                      <div className="card">
                        <div className="card-body">
                          <div className="image-block">
                            <img src={home.img} alt={home.location} />
                          </div>
                          <div className="right-block">
                            <h5 className="card-title">
                              ₹ {home.price} / <span>mo</span>
                            </h5>
                            {home.verified && (
                              <span className="sub-text">
                                <VerifiedIcon />
                                Verified
                              </span>
                            )}
                            <div className="amenitites">
                              {home.amenities.map((amenity, subIndex) => {
                                return (
                                  <span className="amenity" key={amenity.title}>
                                    <span className="icon">{amenity.icon}</span>
                                    {amenity.title}
                                  </span>
                                );
                              })}
                            </div>
                            <p className="card-text">
                              {" "}
                              <span className="icon">
                                <LocationIcon />
                              </span>
                              {home.location}
                            </p>
                            <a href="#" className="btn btn-green">
                              Ready to move
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="slider-controls-wrapper">
          <div className="d-flex controls-row">
            <div
              className={`prev-btn ${
                activeSlide === 0 ? "disabled" : "enabled"
              }`}
              onClick={() => {
                sliderRef.slickPrev();
              }}
            >
              <PrevArrowIcon color="var(--color-pilot-blue)" />
            </div>
            <div className="slider-range">
              1 <span className="rect-line"></span> {rentalHomes.length}
            </div>
            <div
              className={`next-btn ${
                activeSlide === rentalHomes.length - 1 ? "disabled" : "enabled"
              }`}
              onClick={() => {
                sliderRef.slickNext();
              }}
            >
              <NextArrowIcon color="var(--color-pilot-blue)" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default RentalHomesSlider;
