import React from "react";
import { GreyLeftStarIcon, GreyRightStarIcon } from "../Assets/Icons.tsx";
import "./style.scss";
interface SubHeadingProps {
  heading: string;
  bothArrow?: boolean;
  color?: string;
}
const SubHeading = (props: SubHeadingProps) => {
  const { heading, bothArrow, color } = props;
  return (
    <>
      <div className="guesthom-sub-heading-wrapper">
        {bothArrow && <GreyLeftStarIcon color={color} />}
        <h6 className="heading" style={{ color: color }}>
          {heading}
        </h6>
        <GreyRightStarIcon color={color} />
      </div>
    </>
  );
};
export default SubHeading;
