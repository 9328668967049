import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./mapStyle.scss";

const locations = [
    {
        id: 1,
        position: [23.0225, 72.5714],
        title: "South Bopal",
        buyPrice: "7,213",
        rentRange: "40k- 4L",
        propertiesCount: 287,
        rentPropertiesCount: 1278,
    },
    {
        id: 2,
        position: [23.0425, 72.5814],
        title: "Gota",
        buyPrice: "7,213",
        rentRange: "40k- 4L",
        propertiesCount: 287,
        rentPropertiesCount: 1278,
    },
    {
        id: 3,
        position: [23.0325, 72.5914],
        title: "S.P Ring Road",
        buyPrice: "7,213",
        rentRange: "40k- 4L",
        propertiesCount: 287,
        rentPropertiesCount: 1278,
    },
];

const PropertyMap = () => {
    console.log("locations", locations);
    const [map, setMap] = useState(null);
    const [activeMarker, setActiveMarker] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        const mapInstance = L.map("map", {
            center: [23.0325, 72.5814],
            zoom: 13,
            zoomControl: false,
            attributionControl: false,
        });

        L.tileLayer(
            "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
            {
                maxZoom: 19,
                attribution: "",
            }
        ).addTo(mapInstance);

        L.control
            .zoom({
                position: "topright",
            })
            .addTo(mapInstance);

        setMap(mapInstance);

        return () => {
            mapInstance.remove();
        };
    }, []);

    useEffect(() => {
        if (!map) return;

        markers.forEach((marker) => marker.remove());

        const newMarkers = locations.map((location) => {
            const customIcon = L.divIcon({
                className: "location-marker",
                html: `
                    <div class="ripple-container">
                        <div class="ripple"></div>
                        <div class="ripple"></div>
                        <div class="ripple"></div>
                        <div class="marker-dot"></div>
                    </div>
                `,
                iconSize: [200, 200],
                iconAnchor: [100, 100],
            });

            const marker = L.marker(location.position, { icon: customIcon })
                .addTo(map)
                .on("mouseover", () => {
                    setActiveMarker(location.id);
                    const point = map.latLngToLayerPoint(location.position);
                    setTooltipPosition({ top: point.y, left: point.x });
                })
                .on("mouseout", () => {
                    setActiveMarker(null);
                });

            return marker;
        });

        setMarkers(newMarkers);

        // Fit map to show all markers
        const bounds = L.latLngBounds(locations.map((loc) => loc.position));
        map.fitBounds(bounds, { padding: [50, 50] });

        return () => {
            newMarkers.forEach((marker) => marker.remove());
        };
    }, [map]);

    return (
        <div className="map-container">
            <div className="container"></div>
            <div id="map" style={{ height: "100%", width: "100%" }} />

            {locations.map(
                (location) =>
                    activeMarker === location.id && (
                        <div
                            key={location.id}
                            className="property-card"
                            style={{
                                top: `${tooltipPosition.top}px`,
                                left: `${tooltipPosition.left}px`,
                                position: "absolute",
                            }}
                        >
                            <h6>{location.title}</h6>
                            <div className="d-flex map-propery-row first-child">
                                <div className="property-info">
                                    <div className="property-type">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                        </svg>
                                        Buy
                                    </div>
                                    <div className="property-value">
                                        {location.rentPropertiesCount.toLocaleString()}
                                    </div>
                                </div>
                                <div className="property-info">
                                    <div className="property-type">
                                        Properties Available
                                    </div>
                                    <div className="property-value">
                                        {location.propertiesCount}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex map-propery-row">
                                <div className="property-info">
                                    <div className="property-type">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                                        </svg>
                                        Rent
                                    </div>
                                    <div className="property-value">
                                        ₹{location.rentRange}
                                    </div>
                                </div>
                                <div className="property-info">
                                    <div className="property-type">
                                        Properties Available
                                    </div>
                                    <div className="property-value">
                                        {location.rentPropertiesCount}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            )}

            <button className="view-all-button">
                View All Properties Near You
            </button>
        </div>
    );
};

export default PropertyMap;
