import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import RangeSlider from "../range-slider/range-slider.tsx";
import "./style.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

const MortgageCalculator = () => {
  const [totalAmount, setTotalAmount] = useState(8000000);
  const [loanPeriod, setLoanPeriod] = useState(25);
  const [downPayment, setDownPayment] = useState(2000000);
  const [interestRate, setInterestRate] = useState(4);
  const [monthlyInstallment, setMonthlyInstallment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);

  useEffect(() => {
    const loanAmount = totalAmount - downPayment;
    const monthlyRate = interestRate / 12 / 100;
    const months = loanPeriod * 12;

    const installment =
      (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, months)) /
      (Math.pow(1 + monthlyRate, months) - 1);

    setMonthlyInstallment(installment.toFixed(2));
    setTotalInterest((installment * months - loanAmount).toFixed(2));
  }, [totalAmount, loanPeriod, downPayment, interestRate]);

  const data = {
    labels: ["Loan Amount", "Down Payment", "Total Interest"],
    datasets: [
      {
        data: [
          totalAmount - downPayment,
          downPayment,
          parseFloat(totalInterest),
        ],
        backgroundColor: ["#8EC9A1", "032850", "#8EC9A180"], // Colors for segments
        borderColor: "#ffffff", // White border between segments
        borderWidth: 0, // Border thickness
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            });
            return `${context.label}: ${value}`;
          },
        },
      },
    },
    cutout: "60%", // Makes the pie chart a donut chart
    maintainAspectRatio: true,
  };

  return (
    <div className="mortgage-calculator">
      <div className="calculator-container">
        <div className="inputs">
          <p>Calculate and view the monthly mortgage on this townhouse</p>

          {/* Total Amount */}
          <div className="input-group">
            <label>Total Amount</label>
            <div className="input-slider">
              <input
                type="number"
                min="5000000"
                max="20000000"
                step="100000"
                value={totalAmount}
                onChange={(e) => setTotalAmount(Number(e.target.value))}
              />
              <RangeSlider
                onChange={(value) => {
                  setTotalAmount(Number(value));
                }}
                max={20000000}
                value={totalAmount || 0}
              />
            </div>
          </div>

          {/* Loan Period */}
          <div className="input-group">
            <label>Loan Period</label>
            <div className="input-slider">
              <input
                type="number"
                min="5"
                max="30"
                step="1"
                value={loanPeriod}
                onChange={(e) => setLoanPeriod(Number(e.target.value))}
              />
              <RangeSlider
                onChange={(value) => {
                  setLoanPeriod(Number(value));
                }}
                max={30}
                value={loanPeriod || 0}
              />
            </div>
          </div>

          {/* Down Payment */}
          <div className="input-group">
            <label>Down Payment</label>
            <div className="input-slider">
              <input
                type="number"
                min="500000"
                max={totalAmount / 2}
                step="100000"
                value={downPayment}
                onChange={(e) => setDownPayment(Number(e.target.value))}
              />
              <RangeSlider
                onChange={(value) => {
                  setDownPayment(Number(value));
                }}
                max={totalAmount / 2}
                value={downPayment || 0}
              />
            </div>
          </div>

          {/* Interest Rate */}
          <div className="input-group">
            <label>Interest Rate</label>
            <div className="input-slider">
              <input
                type="number"
                min="1"
                max="10"
                step="0.1"
                value={interestRate}
                onChange={(e) => setInterestRate(Number(e.target.value))}
              />
              <RangeSlider
                onChange={(value) => {
                  setInterestRate(Number(value));
                }}
                max={10}
                value={interestRate || 0}
              />
            </div>
          </div>
        </div>

        {/* Results Section */}
        <div className="results">
          <div className="home-pie-chart">
            <Pie data={data} options={options} />
          </div>

          <h3>₹{monthlyInstallment}</h3>
          <p>Installment per month</p>
          <ul>
            <li>
              Loan Amount: &nbsp;&nbsp; ₹
              {(totalAmount - downPayment).toLocaleString()}
            </li>
            <li>Down Payment: &nbsp;&nbsp;₹{downPayment.toLocaleString()}</li>
            <li>
              Total Interest:&nbsp;&nbsp; ₹{totalInterest.toLocaleString()}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MortgageCalculator;
