import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import ClinetSection from "./ClinetSection";
import WhyWeDifferent from "./WhyWeDifferent";
import PropertyCarousel from "./PropertyCarosal";

import "../styles/home.scss";

import BlogSection from "./blog.tsx";
import PopularSearch from "./popular-search.tsx";
import Footer from "./footer.tsx";
import SliderSection from "./slider-section.js";
import RentalHomesSlider from "./rental-home-slider.js";
import PropertyMap from "./PropertyMap.js";
import SubHeading from "./sub-heading.tsx";

const Home = () => {
  const blogs = [
    {
      img: "/images/blog-img-1.png",
      date: "Sunday, 1 Dec 2024",
      title: "Top 10 most expensive localities In Ahmedabad",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/expensive-localities",
    },
    {
      img: "/images/blog-img-2.png",
      date: "Sunday, 1 Dec 2024",
      title: "Ahmedabad RERA fines Countrywide Promoters",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/countrywide-promoters",
    },
    {
      img: "/images/blog-img-3.png",
      date: "Sunday, 1 Dec 2024",
      title: "How to secure your house to stay safe from robbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/secure-house",
    },
  ];
  return (
    <div>
      <Header />
      <Hero />
      <ClinetSection />
      <WhyWeDifferent />
      <PropertyCarousel />
      <RentalHomesSlider />
      <SliderSection />
      <section className="map-section">
        <div className="container">
          <div className="margin-auto-title">
            <SubHeading heading="Top Localities Near You" bothArrow />
          </div>
          <h2 className="text-5xl font-medium">
            <span className="text-blue-950 font-nohemi font-normal">
              Hotspots Near You,{" "}
            </span>
            <br />
            <span className="text-green-400 font-nohemi font-normal">
              Your Next Move
            </span>
            <span className="text-blue-950 font-nohemi font-normal">
              {" "}
              Awaits
            </span>
          </h2>
          <PropertyMap />
        </div>
      </section>

      <BlogSection cards={blogs} />
      <PopularSearch />
      <Footer />
    </div>
  );
};

export default Home;
