import React from "react";
import Startup from "../Assets/Images/startup.png";
import Applay from "../Assets/Images/applay.png";
import Client1 from "../Assets/Images/client1.png";
import Client2 from "../Assets/Images/client2.png";
import Techlly from "../Assets/Images/techlly.png";
import Software from "../Assets/Images/software.png";
import Marketly from "../Assets/Images/marketly.png";
import SubHeading from "./sub-heading.tsx";

const ClientSection = () => {
  return (
    <div className="overflow-hidden guesthom-client-section">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center client-heading-block">
          <SubHeading
            color="white"
            heading="Trusted by Industry Leaders"
            bothArrow
          />
        </div>

        <div className="relative">
          <div className="flex items-center gap-40 animate-marquee">
            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Startup}
                alt="Camera company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-4 shrink-0">
              <img
                src={Applay}
                alt="Applay company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Client1}
                alt="Apply company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Client2}
                alt="Software company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Techlly}
                alt="Techlify company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Marketly}
                alt="Techlify company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Software}
                alt="Techlify company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            {/* Duplicate set for seamless loop */}
            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Startup}
                alt="Camera company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-4 shrink-0">
              <img
                src={Applay}
                alt="Applay company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Client1}
                alt="Apply company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Client2}
                alt="Software company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Techlly}
                alt="Techlify company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Marketly}
                alt="Techlify company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>

            <div className="flex items-center space-x-2 shrink-0">
              <img
                src={Software}
                alt="Techlify company logo"
                className="w-30 h-auto opacity-70 hover:opacity-100 transition-opacity"
              />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes marquee {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .animate-marquee {
          animation: marquee 30s linear infinite;
          display: flex;
        }
      `}</style>
    </div>
  );
};

export default ClientSection;
