import React, { ChangeEvent, useEffect, useState } from "react";
import "./style.scss";

interface Props {
  onChange: (value: string) => void;
  value?: number;
  min?: number;
  max: number;
}
const RangeSlider = (props: Props) => {
  const { onChange, value, min = 0, max } = props;
  const [rangeValue, setRangeValue] = useState<number>(value ?? 0);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRangeValue(Number(e.target.value));
    onChange(e.target.value);
  };
  useEffect(() => {
    if (value != undefined) {
      setRangeValue(value);
    }
  }, [value]);

  return (
    <div className="guesthom-range-slider-container">
      <input
        type="range"
        min={min}
        max={max}
        value={rangeValue}
        className="range-slider"
        onChange={handleChange}
        style={
          {
            "--value": rangeValue.toString(),
            "--min": min,
            "--max": max,
          } as React.CSSProperties
        }
      />
    </div>
  );
};

export default RangeSlider;
