import React from "react";
import "./../styles/faq.scss";
import { FaqArrowIcon, SearchIcon } from "../Assets/Icons.tsx";
import Header from "../Components/Header";
import Footer from "../Components/footer.tsx";
import PopularSearch from "../Components/popular-search.tsx";
import { Link } from "react-router-dom";

const FAQ = () => {
  const general = [
    {
      title: "About Brand",
      link: "/faq/about-brand",
    },
    {
      title: "Account",
      link: "/faq/account",
    },
    {
      title: "Usage",
      link: "/faq/usage",
    },
  ];
  const rental = [
    {
      title: "Finding Rentals",
      link: "/faq/finding-rentals",
    },
    {
      title: "Application Process",
      link: "/faq/application-process",
    },
    {
      title: "Documents",
      link: "/faq/documents",
    },
    {
      title: "Visit Scheduling",
      link: "/faq/visit-scheduling",
    },
    {
      title: "Payments & Deposits",
      link: "/faq/payments",
    },
  ];
  const sellers = [
    {
      title: "Property Listing",
      link: "/faq/property-listing",
    },
    {
      title: "Property Management",
      link: "/faq/property-management",
    },
    {
      title: "Documents",
      link: "/faq/documents",
    },
    {
      title: "Payments & Deposits",
      link: "/faq/payments",
    },
  ];
  const safety = [
    {
      title: "Technical Support",
      link: "/faq/technical-support",
    },
    {
      title: "User Safety & Legal",
      link: "/faq/safety-legal",
    },
  ];
  return (
    <div className="guesthom-faq">
      <Header />
      <section className="guesthom-hero-section">
        <div className="container">
          <div className="title">
            <h1>FAQs</h1>
            <div className="search-container">
              <input
                type="text"
                className="search-input"
                placeholder="Search for FAQs"
              />
              <button className="search-button">
                <span className="search-icon">
                  <SearchIcon />
                </span>{" "}
                Search
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="question">
        <div className="container">
          <h2>General Questions</h2>
          <div className="d-flex q-row">
            {general.map((q, index) => {
              return (
                <Link to={q.link} key={index}>
                  <div className="question-card">
                    {q.title}
                    <span className="icon">
                      <FaqArrowIcon />
                    </span>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>
      <section className="rental-buyers">
        <div className="container">
          <h2>For Renters & Buyer</h2>
          <div className="d-flex q-row">
            {rental.map((q, index) => {
              return (
                <Link to={q.link} key={index}>
                  <div className="question-card">
                    {q.title}
                    <span className="icon">
                      <FaqArrowIcon />
                    </span>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>
      <section className="common-faq bg-white">
        <div className="container">
          <h2>For Sellers & Landlords</h2>
          <div className="d-flex q-row">
            {sellers.map((q, index) => {
              return (
                <Link to={q.link} key={index}>
                  <div className="question-card">
                    {q.title}
                    <span className="icon">
                      <FaqArrowIcon />
                    </span>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>
      <section className="common-faq">
        <div className="container">
          <h2>Safety & Legal</h2>
          <div className="d-flex q-row">
            {safety.map((q, index) => {
              return (
                <Link to={q.link} key={index}>
                  <div className="question-card">
                    {q.title}
                    <span className="icon">
                      <FaqArrowIcon />
                    </span>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>
      <section className="doubts">
        <div className="container">
          <p>
            Still have doubts? <span>Mail Us</span> and our team will get in
            touch with you.
          </p>
        </div>
      </section>
      <PopularSearch />
      <Footer />
    </div>
  );
};
export default FAQ;
