import React from "react";
import {
  FbIcon,
  InstaIcon,
  LogoFooter,
  TwitterIcon,
  YouTubeIcon,
} from "../Assets/Icons.tsx";

const Footer = () => {
  console.log("Footer");
  return (
    <>
      <footer className="site-footer">
        <div className="container">
          <div className="footer-container">
            <div className="form-card">
              <h6 className="small-heading">Support</h6>
              <h5 className="form-title">
                Need help? <br />
                Hit us up for a call!
              </h5>
              <form>
                <input
                  type="text"
                  placeholder="Name"
                  className="footer-input"
                />
                <input
                  type="text"
                  placeholder="Phone number"
                  className="footer-input"
                />
                <button className="footer-btn">Send a request</button>
              </form>
              <span className="privacy-text">Privacy</span>
            </div>
            <div className="nav-links-block">
              <div className="d-flex nav-link-list">
                <div className="left-column">
                  <div className="d-flex link-list-row">
                    <div className="link-list">
                      <h6 className="small-heading">Core</h6>
                      <h5 className="nav-title">Products</h5>
                      <h5 className="nav-title">Blogs</h5>
                      <h5 className="nav-title">Services</h5>
                    </div>
                    <div className="link-list">
                      <h6 className="small-heading">About us</h6>
                      <h5 className="nav-title">Technologies</h5>
                      <h5 className="nav-title">Contacts</h5>
                    </div>
                    <div className="link-list">
                      <h6 className="small-heading">Contact us</h6>
                      <h5 className="nav-title">+83476 35563</h5>
                      <h5 className="nav-title">hello@guesthom.com</h5>
                      <h5 className="nav-title">Ahmedabad</h5>
                    </div>
                  </div>
                </div>
                <div className="right-column">
                  <LogoFooter />
                </div>
              </div>
              <div className="d-flex footer-bottom-row">
                <div className="social-media-wrapper">
                  <i className="social-icon">
                    <FbIcon />
                  </i>
                  <i className="social-icon">
                    <InstaIcon />
                  </i>
                  <i className="social-icon">
                    <YouTubeIcon />
                  </i>
                  <i className="social-icon">
                    <TwitterIcon />
                  </i>
                </div>
                <h5 className="copy-right-text">@2025 - Copyright</h5>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
