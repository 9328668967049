import React from "react";

import Header from "../../Components/Header";
import BlogSection from "../../Components/blog.tsx";
import PopularSearch from "../../Components/popular-search.tsx";
import Footer from "../../Components/footer.tsx";

import {
  BlogBackIcon,
  FBFillIcon,
  LeftArrowIcon,
  LinkedInFillIcon,
  RightArrowIcon,
  TwitterFillIcon,
} from "../../Assets/Icons.tsx";
import { Link } from "react-router-dom";

const BlogDetail = () => {
  const blogs = [
    {
      img: "/images/blog-img-2.png",
      date: "Sunday, 1 Dec 2024",
      title: "Top 10 most expensive localities In Ahmedabad",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "#",
    },
    {
      img: "/images/blog-img-3.png",
      date: "Sunday, 1 Dec 2024",
      title: "Ahmedabad RERA fines Countrywide Promoters",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "#",
    },
    {
      img: "/images/blog-img-4.png",
      date: "Sunday, 1 Dec 2024",
      title: "How to secure your house to stay safe from robbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "#",
    },
  ];
  return (
    <div className="guesthom-blogs-detail">
      <Header />
      <section className="blog-details-section">
        <div className="container">
          <div className="d-flex blog-detail-outer-row">
            <div className="left-block">
              <div className="title-area">
                <div className="read-detail">
                  <span>
                    <Link to="/blog">
                      <BlogBackIcon />
                    </Link>
                  </span>
                  12 min read
                </div>
                <h1>Real estate giants unite to dominate Indian market</h1>
              </div>
              <div className="detail-wrapper">
                <div className="blog-img-block">
                  <img src="/images/blog-img-4.png" alt="blog-hero-img" />
                </div>
                <div className="blog-description">
                  <p>
                    In a strategic move aimed at capitalizing on growth
                    opportunities in the real estate sector, Peninsula Land
                    Limited, Delta Corp, and the AA Group have joined forces to
                    establish a new real estate platform. With a combined
                    investment of Rs. 7.65 billion, the three renowned companies
                    are set to revolutionize the Indian real estate market.
                  </p>
                  <h2>A Platform for Innovation and Growth</h2>
                  <p>
                    This groundbreaking venture harnesses the strengths and
                    expertise of each partner, allowing for the development of
                    residential, commercial, and mixed-use projects across
                    India. The collaborative platform aims to deliver
                    value-driven real estate solutions to meet evolving market
                    demands.
                  </p>
                  <h2>
                    Peninsula Land: Quality Projects and Premium Developments
                  </h2>
                  <ul>
                    <li>
                      Peninsula Land, known for its exceptional track record in
                      delivering premium residential and commercial projects,
                      brings decades of experience to the partnership. Their
                      expertise and dedication to quality construction and
                      design make them a valuable asset within the
                      collaboration.
                    </li>
                    <li>
                      With a diverse portfolio of businesses that includes real
                      estate, infrastructure, and hospitality, the AA Group adds
                      significant value to the partnership. Their strategic
                      insights and operational capabilities will help shape the
                      development of the real estate platform.
                    </li>
                    <li>
                      Through collaborative efforts, the three partners aim to
                      create a robust platform that not only meets but exceeds
                      the expectations of stakeholders. By sharing their
                      resources and knowledge, Peninsula Land, Delta Corp, and
                      the AA Group aspire to establish themselves as leaders in
                      India’s dynamic real estate market.
                    </li>
                  </ul>
                  <img src="/images/blog-detail-img.png" alt="blog-img" />
                  <h3>Achieving Superior Value and Stakeholder Satisfaction</h3>
                  <p>
                    The launch of this realty platform affirms the shared
                    commitment of Peninsula Land, Delta Corp, and the AA Group
                    in driving innovation, sustainability, and customer-centric
                    practices within the real estate sector. The partners aim to
                    go beyond traditional development by embracing cutting-edge
                    technologies and sustainable construction methods.
                  </p>
                  <blockquote>
                    <p>
                      "Apart from being a literature student and a theatre
                      practitioner, Aayush is a daydreamer! He loves poetry and
                      just as a poet should be, he’s witty, opinionated and
                      completely clueless about life. Reach out to him anytime
                      for some good old sher-o-shayari. Here, he writes about
                      the quirkiest and the most interesting of things. To the
                      rhythm of life and words, cheers!"
                    </p>
                  </blockquote>
                  <h2>Conclusion</h2>
                  <p>
                    In a strategic alliance of industry leaders, Peninsula Land
                    Limited, Delta Corp, and the AA Group have forged a
                    pioneering real estate platform. With an investment of Rs.
                    7.65 billion in place, the partnership intends to deliver
                    real estate solutions that redefine conventional
                    expectations. Ready to embrace innovation, sustainability,
                    and customer-centricity, this collaboration aims to
                    establish itself as a dominant force in India’s
                    ever-evolving real estate market while contributing to the
                    nation’s progress.
                  </p>
                </div>
                <div className="author-bio">
                  <div className="d-flex bio-row">
                    <div className="left-item">
                      <div className="small-title">Author</div>
                      <div className="d-flex author-details">
                        <div className="author-img">
                          <img
                            src="/images/blog-img-2.png"
                            alt="author-image"
                          />
                        </div>
                        <div className="author-name">Shanaya Rana</div>
                      </div>
                    </div>
                    <div className="right-item">
                      <div className="small-title">Share on</div>
                      <div className="d-flex social-shares">
                        <LinkedInFillIcon />
                        <FBFillIcon />
                        <TwitterFillIcon />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex post-row">
                    <div className="left-item">
                      <span>
                        <LeftArrowIcon />
                      </span>
                      Previous Post
                    </div>
                    <div className="right-item">
                      Next Post{" "}
                      <span>
                        <RightArrowIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-block sticky-block">
              <div className="blog-other-details-wrapper">
                <div className="small-title">Author</div>
                <div className="d-flex author-details">
                  <div className="author-img">
                    <img src="/images/blog-img-2.png" alt="author-image" />
                  </div>
                  <div className="author-name">Shanaya Rana</div>
                </div>
                <div className="small-title">Published on</div>
                <div className="published-date">Dec 10,2024</div>
                <div className="small-title">Share on</div>
                <div className="d-flex social-shares">
                  <LinkedInFillIcon />
                  <FBFillIcon />
                  <TwitterFillIcon />
                </div>
                <div className="related-article">
                  <div className="blog-card">
                    <img src="/images/blog-img-3.png" alt="blog-img" />
                    <div className="content-area">
                      <h5>Hot Spots, Cool Rentals. Find Your Vibe!</h5>
                      <div className="cta">
                        View Properties to Rent{" "}
                        <span>
                          <RightArrowIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BlogSection
        subHeading="Suggestions"
        title="You may also like"
        cards={blogs}
      />
      <PopularSearch />
      <Footer />
    </div>
  );
};
export default BlogDetail;
