import React from "react";
import "./style.scss";
import SubHeading from "./sub-heading.tsx";
import SectionTitle from "./title.tsx";
import {
  NextArrowIcon,
  PrevArrowIcon,
  RightArrowIcon,
} from "../Assets/Icons.tsx";
import { Link } from "react-router-dom";

interface BlogCard {
  img: string;
  date: string;
  title: string;
  description: string;
  link: string;
}
interface Props {
  subHeading?: string;
  title?: string;
  searchElement?: React.ReactNode;
  cards: BlogCard[];
  pagination?: boolean;
}
const BlogSection = (props: Props) => {
  const { subHeading, title, searchElement, cards, pagination } = props;
  return (
    <>
      {" "}
      <section className="recent-blogs">
        <div className="container">
          <div className="title-wrapper">
            <div className="d-flex outer-div">
              <div className="left-block">
                <SubHeading heading={subHeading ?? "Always Up-to-Date"} />
                <SectionTitle title={title ?? "Recent Blogs"} noPadding />
              </div>
              <div className="right-block">
                {!searchElement && (
                  <Link to="/blog">
                    <button className="btn btn-secondary">
                      View All blogs
                    </button>
                  </Link>
                )}
                {searchElement}
              </div>
            </div>
          </div>
          <div className="grid-graphic">
            <img src={"/images/grid-graphic.png"} alt="blog" />
          </div>
        </div>
      </section>
      <section className="blog-cards-wrapper">
        <div className="container">
          <div className="blog-body">
            <div className="d-flex blog-row">
              {cards &&
                cards.map((card, index) => {
                  return (
                    <Link to={card.link} key={index} className="blog-card">
                      <div className="blog-card-inner-wrapper">
                        <div className="img-block">
                          <img src={card.img} alt={card.title} />
                        </div>
                        <div className="content-block">
                          <span className="date">{card.date}</span>
                          <h5 className="title">{card.title}</h5>
                          <p className="description">{card.description}</p>
                        </div>
                        <div className="action-btn">
                          <button className="btn read-more-btn">
                            Read Blog{" "}
                            <span>
                              <RightArrowIcon />
                            </span>
                          </button>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
            {pagination && (
              <div className="slider-controls-wrapper">
                <div className="d-flex controls-row">
                  <div className={`prev-btn`}>
                    <PrevArrowIcon color="var(--color-text)" />
                  </div>
                  <div className="slider-range">
                    1 <span className="rect-line"></span> {cards?.length}
                  </div>
                  <div className={`next-btn`}>
                    <NextArrowIcon color="var(--color-text)" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
export default BlogSection;
