import React, { useState } from "react";
import {
  BlogBackIcon,
  FaqArrowIcon,
  RightArrowIcon,
} from "../Assets/Icons.tsx";
import Header from "../Components/Header.js";
import PopularSearch from "../Components/popular-search.tsx";
import Footer from "../Components/footer.tsx";
import { Link } from "react-router-dom";

const FAQDetail = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const data = [
    {
      question: "How do I search for a rental property?",
      answer: "Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue.",
    },
    {
      question: "Can I filter properties based on my budget or preferences?",
      answer: "Curabitur ullamcorper ultricies nisi. Nam eget dui.",
    },
    {
      question: "What information is available about each property?",
      answer: "Etiam rhoncus. Maecenas tempus, tellus eget.",
    },
    {
      question: "How do I apply for a rental?",
      answer: "Maecenas tempus, tellus eget condimentum rhoncus.",
    },
    {
      question: "Are there any fees for renting through GuestHom?",
      answer: "Etiam ultricies nisi vel augue.",
    },
  ];
  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="guesthom-faq-detail">
      <Header />
      <section className="faq-detail-hero-section">
        <div className="container">
          <div className="d-flex">
            <div className="left-block">
              <div className="back-to-faq">
                <span className="back-icon">
                  <Link to="/faq">
                    <BlogBackIcon />
                  </Link>
                </span>
                <span>Back to FAQs</span>
              </div>
              <h2>Finding Rentals</h2>
              <h4>Still have doubts?</h4>
              <p>No worries, our team will get in touch.</p>
              <div className="action-btn">
                <button className="btn read-more-btn">
                  Mail Us
                  <span>
                    <RightArrowIcon />
                  </span>
                </button>
              </div>
            </div>
            <div className="right-block"></div>
          </div>
        </div>
      </section>
      <section className="faq-detail-hero-section faq-accordions">
        <div className="container">
          <div className="d-flex">
            <div className="left-block"></div>
            <div className="right-block">
              <div className="accordion">
                {data.map((item, index) => (
                  <div key={index} className="accordion-item">
                    <div
                      className={`accordion-header ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => handleClick(index)}
                    >
                      <span>{item.question}</span>
                      <span>
                        {" "}
                        <FaqArrowIcon />
                      </span>
                    </div>
                    {activeIndex === index && (
                      <div className="accordion-content">{item.answer}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopularSearch />
      <Footer />
    </div>
  );
};
export default FAQDetail;
