import React from "react";
import Logo from "../Assets/Images/Logo.png";

const Header = () => {
  return (
    <header className="w-full border-b border-gray-200 bg-white py-4 px-6">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <a
            href="/"
            className="flex items-center text-xl font-semibold text-gray-800"
          >
            <img src={Logo} alt="Guesthom" width={120} height={24} />
          </a>
        </div>

        <nav className="hidden md:flex items-center space-x-8 header-nav-items">
          <a href="/rent" className="text-blue-950 hover:text-gray-900">
            Rent
          </a>
          <a href="/buy" className="text-blue-950 hover:text-gray-900">
            Buy
          </a>
          <a href="/manage" className="text-blue-950 hover:text-gray-900">
            Manage Property
          </a>
        </nav>

        <div className="flex items-center space-x-4">
          <a
            href="/login"
            className="p-4 py-2 text-gray-700 hover:text-gray-900"
          >
            Log In
          </a>
          <a
            href="/signup"
            className="px-4 py-2 bg-blue-950 text-white rounded-lg blue-950"
          >
            Get Started
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
