import React from "react";
import { RightArrowIcon, SearchIcon } from "../../Assets/Icons.tsx";
import "../../styles/_blog.scss";
import BlogSection from "../../Components/blog.tsx";
import PopularSearch from "../../Components/popular-search.tsx";
import Footer from "../../Components/footer.tsx";
import Header from "../../Components/Header.js";
import { Link } from "react-router-dom";

const BlogHome = () => {
  const blogs = [
    {
      img: "/images/blog-img-2.png",
      date: "Sunday, 1 Dec 2024",
      title: "Top 10 most expensive localities In Ahmedabad",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/top-10-locallities",
    },
    {
      img: "/images/blog-img-3.png",
      date: "Sunday, 1 Dec 2024",
      title: "Ahmedabad RERA fines Countrywide Promoters",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/countrywide-promoters",
    },
    {
      img: "/images/blog-img-4.png",
      date: "Sunday, 1 Dec 2024",
      title: "How to secure your house to stay safe from robbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/secure-house",
    },
  ];

  const allBlogs = [
    {
      img: "/images/blog-img-3.png",
      date: "Sunday, 1 Dec 2024",
      title: "16 Attractive double fan POP design for modern home",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/POP-design",
    },
    {
      img: "/images/blog-img-1.png",
      date: "Sunday, 1 Dec 2024",
      title: "Ahmedabad RERA fines Countrywide Promoters",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/countrywide-promoters",
    },
    {
      img: "/images/blog-img-2.png",
      date: "Sunday, 1 Dec 2024",
      title: "Top 10 most expensive localities In Ahmedabad",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/blog-title",
    },
    {
      img: "/images/blog-img-1.png",
      date: "Sunday, 1 Dec 2024",
      title: "16 Attractive double fan POP design for modern home",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/blog-title",
    },
    {
      img: "/images/blog-img-4.png",
      date: "Sunday, 1 Dec 2024",
      title: "Ahmedabad RERA fines Countrywide Promoters",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/blog-title",
    },
    {
      img: "/images/blog-img-2.png",
      date: "Sunday, 1 Dec 2024",
      title: "Top 10 most expensive localities In Ahmedabad",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.",
      link: "/blog/blog-title",
    },
  ];
  return (
    <div className="guesthom-blogs-home">
      <Header />
      <section className="featured-blogs">
        <div className="container">
          <div className="title-wrapper">
            <h2>Featured Blogs</h2>
          </div>
          <div className="d-flex featured-blogs-row">
            <div className="left-block">
              <Link to="/blog/real-estate-giants">
                <div className="blog-card">
                  <div className="img-block">
                    <img src={"/images/blog-img-1.png"} alt="blog" />
                  </div>
                  <div className="content-block">
                    <span className="date">Sunday, 1 Dec 2024</span>
                    <h3 className="title">
                      Real estate giants unite to dominate Indian market
                    </h3>
                    <p className="description">
                      If you’ve read this far and you’re wondering what “web3”
                      is exactly, this is one of those need-to-knows, and it’s
                      pretty simple. We’ll explain more below
                    </p>
                  </div>
                  <div className="action-btn">
                    <button className="btn read-more-btn">
                      Read Blog{" "}
                      <span>
                        <RightArrowIcon />
                      </span>
                    </button>
                  </div>
                </div>
              </Link>
            </div>
            <div className="right-block">
              <div className="cards-wrapper">
                {blogs.map((blog, index) => {
                  return (
                    <Link to={blog.link} key={index}>
                      <div className="blog-card">
                        <div className="d-flex card-row">
                          <div className="img-block">
                            <img src={blog.img} alt={blog.title} />
                          </div>
                          <div className="content-block">
                            <span className="date">{blog.date}</span>
                            <h5 className="title">{blog.title}</h5>
                            <p className="description">{blog.description}</p>
                            <div className="action-btn">
                              <button className="btn read-more-btn">
                                Read Blog{" "}
                                <span>
                                  <RightArrowIcon />
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="grid-graphic">
          <img src={"/images/grid-graphic.png"} alt="blog-graphic" />
        </div>
      </section>
      <BlogSection
        subHeading="Everything You Need to Stay Updated"
        title="All blogs & articles"
        cards={allBlogs}
        pagination={true}
        searchElement={
          <>
            <div className="search-container">
              <input
                type="text"
                className="search-input"
                placeholder="Search for blogs"
              />
              <button className="search-button">
                <span className="search-icon">
                  <SearchIcon />
                </span>{" "}
                Search
              </button>
            </div>
          </>
        }
      />
      <PopularSearch />
      <Footer />
    </div>
  );
};
export default BlogHome;
