import React from "react";

import RupeeWIthArrow from "../Assets/Images/rupeewitharrow.png";
import HomeWithStar from "../Assets/Images/homewithstar.png";
import CheckList from "../Assets/Images/checklist.png";
import LeftTopCorner from "../Assets/Images/lefttopcorner.png";
import RightTopCorner from "../Assets/Images/righttopcorner.png";
import SubHeading from "./sub-heading.tsx";

const WhyWeDifferent = () => {
  return (
    <section className="py-20 px-4 relative why-guesthom-section">
      <div className="absolute left-0 top-0 w-40 h-40">
        <img
          src={LeftTopCorner}
          alt="Top left corner decoration"
          className="w-full h-full object-contain"
        />
      </div>

      <div className="absolute right-0 top-0 w-40 h-40">
        <img
          src={RightTopCorner}
          alt="Top right corner decoration"
          className="w-full h-full object-contain"
        />
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <div className="margin-auto-title">
            <SubHeading heading="Why GuestHom" bothArrow />
          </div>

          <h2 className="text-5xl font-medium">
            <span className="text-blue-950 font-nohemi font-normal">What </span>
            <span className="text-green-400 font-nohemi font-normal">
              Differentiate Us
            </span>
            <span className="text-blue-950 font-nohemi font-normal">
              {" "}
              From{" "}
            </span>
            <br />
            <span className="text-blue-950 font-nohemi font-normal">
              Your Local Brokers
            </span>
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          <div className="text-center md:text-left pl-10 border-dashed border-l-2 border-gray-200 w-4/5">
            <div className="mb-6">
              <span className="text-emerald-300 text-xl font-satoshi font-normal">
                1
              </span>
            </div>
            <div className="bg-blue-50 w-16 h-16 rounded-lg mb-6 flex items-center justify-center mx-auto md:mx-0">
              <img src={RupeeWIthArrow} alt="Rupee with arrow image" />
            </div>
            <h3 className="text-xl font-medium text-blue-950 mb-4 font-nohemi">
              Affordable brokerage for everyone
            </h3>
            <p className="text-gray-600 leading-relaxed font-satoshi font-medium">
              Our transparent fee structure ensures significant savings compared
              to traditional brokers, charging only 20% of one month's rent from
              both parties.
            </p>
          </div>

          <div className="text-center md:text-left pl-10 border-dashed border-l-2 border-gray-200 md:mt-24 w-4/5">
            <div className="mb-6">
              <span className="text-emerald-300 text-xl font-satoshi font-normal">
                2
              </span>
            </div>
            <div className="bg-purple-50 w-16 h-16 rounded-lg mb-6 flex items-center justify-center mx-auto md:mx-0">
              <img src={HomeWithStar} alt="Home with star image" />
            </div>
            <h3 className="text-xl font-medium text-blue-950 mb-4 font-nohemi">
              AI powered property matching
            </h3>
            <p className="text-gray-600 leading-relaxed font-satoshi font-medium">
              Get tailored recommendations for prime areas like GIFT City and SG
              Highway, eco-friendly furnished properties, and enjoy a
              streamlined search process that saves time.
            </p>
          </div>

          <div className="text-center md:text-left pl-10 border-dashed border-l-2 border-gray-200 w-4/5">
            <div className="mb-6">
              <span className="text-emerald-300 text-xl font-satoshi font-normal">
                3
              </span>
            </div>
            <div className="bg-green-50 w-16 h-16 rounded-lg mb-6 flex items-center justify-center mx-auto md:mx-0">
              <img src={CheckList} alt="Checklist image" />
            </div>
            <h3 className="text-xl font-medium text-blue-950 mb-4 font-nohemi">
              Verified listings
            </h3>
            <p className="text-gray-600 leading-relaxed font-satoshi font-medium">
              Find thoroughly verified properties with a focus on authenticity,
              quality, modern designs, and premium amenities for a seamless
              living experience.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWeDifferent;
